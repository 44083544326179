import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const RecentScholarlyImpactPage = () => (
  <Layout>
    <Seo title="Legal Notice" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-54">
        <div class="headline_area">
          <h1>Legal Notice</h1>
        </div>
        <div class="format_text">
          <h1>LEGAL INFORMATION AND NOTICES</h1>
          <p>
            Last Updated: August 1, 2010
            <br />
            <br />
            OWNERSHIP OF SITE; AGREEMENT TO TERMS OF USE
            <br />
            CONDITIONS OF USE
            <br />
            Welcome to Trautmansguide.com. Our use of the term
            “Trautmansguide.com” shall refer to Trautman’s Guide™,
            Trautmansguide.com, COGENT INFORMATION ANALYTICS, INC., (“COGENT”)
            and past or future successors, assigns, parents, affiliates or
            subsidiaries. These Terms and Conditions of Use (the “Terms of Use”)
            apply to the Trautman’s Guide™ web site located at
            www.Trautmansguide.com, and all associated sites linked to
            www.Trautmansguide.com by COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™, its successors, assigns, parents, subsidiaries
            and affiliates, including Trautman’s Guide™ sites around the world
            (collectively, the “Site”). The Site is the property of COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ (“Trautman’s
            Guide™”) and its licensors.
          </p>
          <p>
            BY USING THE SITE, YOU AGREE TO THESE TERMS OF USE; IF YOU DO NOT
            AGREE, DO NOT USE THE SITE.
            <br />
            Trautman’s Guide™ reserves the right, at its sole discretion, to
            change, modify, add or remove portions of these Terms of Use, at any
            time. It is your responsibility to check these Terms of Use
            periodically for changes. Your continued use of the Site following
            the posting of changes will mean that you accept and agree to the
            changes. As long as you comply with these Terms of Use, Trautman’s
            Guide™ grants you a personal, non-exclusive, non-transferable,
            limited privilege to enter and use the Site.
            <br />
            In this agreement (the “Agreement”), the words “you” and “your”
            refer to the party utilizing the Trautman’s Guide™ Materials and the
            term “Trautman’s Guide™ Materials” refers to any Materials or
            services (including, but not limited to data, research and reference
            materials) provided by Trautman’s Guide™ and/or its affiliated
            companies.
            <br />
            ACCOUNTS, PASSWORDS AND SECURITY
            <br />
            Certain features or services offered on or through the Site may
            require you to open an account (including setting up a Trautman’s
            Guide™ ID and password). You are entirely responsible for
            maintaining the confidentiality of the information you hold for your
            account, including your password, and for any and all activity that
            occurs under your account as a result of your failing to keep this
            information secure and confidential. You agree to notify Trautman’s
            Guide™ immediately of any unauthorized use of your account or
            password, or any other breach of security. You may be held liable
            for losses incurred by Trautman’s Guide™ or any other user of or
            visitor to the Site due to someone else using your Trautman’s Guide™
            ID, password or account as a result of your failing to keep your
            account information secure and confidential.
            <br />
            You may not use anyone else’s Trautman’s Guide™ ID, password or
            account at any time without the express permission and consent of
            the holder of that Trautman’s Guide™ ID, password or account.
            Trautman’s Guide™ cannot and will not be liable for any loss or
            damage arising from your failure to comply with these obligations.
            <br />
            BIOGRAPHICAL INFORMATION SUBMITTED TO US
            <br />
            Trautman’s Guide™ users have the option to submit biographical and
            personal information for publication in our publications. You are
            solely responsible for ensuring the accuracy of any information you
            submit to Trautmansguide™. Trautmansguide™ reserves the right to
            edit and select material consistent with our choice of style,
            standards, and limitations of space. In the event of errors,
            following receipt of notification of an error, the sole
            responsibility of Trautmansguide™ shall be to correct such errors in
            a subsequent print edition of the relevant publication and to
            correct such errors in the online version(s) within a reasonable
            period of time. You hereby agree that such corrections will be your
            sole remedy. Trautmansguide™ expressly disclaims all other liability
            for loss or incidental or consequential damage, whether arising from
            negligence or other cause, to any party for errors in publishing.
            Your submission (the named individual) of information to
            Trautmansguide™ for inclusion in any of our publications constitutes
            permission for Trautmansguide™ to publish the information in
            electronic database, in print, or any other form, now existing or
            yet unknown, and to distribute such information for publication by
            other parties, without compensation to you or any other person.
            Trautmansguide™ may, from time-to-time make individual’s information
            available to third parties who may send you information through
            e-mail or postal mail about their products and services.
          </p>
          <h2>COMMENTS, COMMUNICATIONS, REVIEWS AND OTHER CONTENT</h2>
          <p>
            Visitors to any Trautman’s Guide™ web site located at
            www.Trautmansguide.com, and all associated sites linked to
            www.Trautmansguide.com by COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™, its successors, assigns, parents, subsidiaries
            and affiliates, including Trautman’s Guide™ sites around the world
            (collectively, the “Site”) may post reviews, comments, photos, and
            other content; send e-mails and other communications; and submit
            suggestions, ideas, comments, questions, or other information, so
            long as the content is not defamatory, infringing of intellectual
            property rights, illegal, invasive of privacy, obscene, threatening,
            or otherwise injurious to third parties or objectionable and does
            not consist of or contain software viruses, political campaigning,
            commercial solicitation, chain letters, mass mailings, or any form
            of “spam.” You may not use a false e-mail address, impersonate any
            person or entity, or otherwise mislead as to the origin of an e-mail
            or other content. COGENT INFORMATION ANALYTICS, INC., dba Trautman’s
            Guide™ reserves the right (but not the obligation) to remove or edit
            such content, but does not regularly review posted content.
            <br />
            If you do post content or submit material, and unless we indicate
            otherwise, you grant COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™ a nonexclusive, royalty-free, perpetual,
            irrevocable, and fully sublicensable right to use, adapt, display,
            reproduce, modify, publish, translate, create derivative works from,
            and distribute such content throughout the world in any media. You
            grant COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ and
            sublicensees the right to use the name that you submit in connection
            with such content, if they choose. You represent and warrant that
            you own or otherwise control all of the rights to the content that
            you post; that the content is accurate; that use of the content you
            supply does not violate this policy and will not cause injury to any
            person or entity; and that you will indemnify COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™ for all claims resulting from
            content you supply. COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™ has the right but not the obligation to monitor
            and edit or remove any activity or content. COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™ takes no responsibility and
            assumes no liability for any content posted by you or any third
            party.
          </p>
          <h2>COMPLIANCE MONITORING AND ENFORCEMENT</h2>
          <p>
            It is an express condition of this Agreement that in using the
            Trautman’s Guide™ Materials: a) you and any authorized users shall
            fully comply with the terms of this Agreement and all applicable
            federal, state, foreign and local statutes and regulations,
            including, but not limited to, laws and regulations regarding
            telemarketing, email, fax marketing, customer solicitation and
            privacy; and b) you and any authorized users shall comply with all
            applicable Canadian Marketing Association (“CMA”) guidelines and
            Direct Marketing Association (“DMA”) guidelines, if you or your
            authorized users are CMA or DMA members. If you or your authorized
            users are not CMA or DMA members, you and your authorized users are
            encouraged to comply with all applicable CMA and DMA guidelines; and
            c) that you and any authorized users shall comply with the Canadian
            Radio-Television &amp; Telecommunications Commission’s regulations
            regarding the use of Automatic Dialing-Announcing Devices and
            Conditions for Unsolicited Live Voice and Facsimile Calls for the
            Purpose of Solicitation as amended from time to time. Trautman’s
            Guide™ further reserves the right to review materials to be used by
            you in promotions to ensure that your use of the Trautman’s Guide™
            Materials is both appropriate and in accordance with the permitted
            uses of the Trautman’s Guide™ Materials; however, COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™’s failure to review any such
            materials shall not constitute COGENT INFORMATION ANALYTICS, INC.,
            dba Trautman’s Guide™’s acceptance of the materials or in any other
            way waive any rights Trautman’s Guide™ may have or limit any
            obligations you may have with regard to the use of the Trautman’s
            Guide™ Materials. Trautman’s Guide™ may also randomly monitor
            (through seeding and other means) your use of the Trautman’s Guide™
            Materials to ensure that your use is in accordance with the
            permitted uses of the Trautman’s Guide™ Materials.
          </p>
          <h2>CONTENT</h2>
          <p>
            All artwork and computer code (collectively, “Content”), graphics,
            logos, music, photographs, sounds, text, trademarks, user
            interfaces, visual interfaces, including but not limited to the
            coordination, design, expression, “look and feel”, selection,
            structure, and arrangement of such Content, contained on the Site is
            owned, controlled or licensed by or to Trautman’s Guide™, and is
            protected by copyright, patent, trade dress, and trademark laws, and
            various other intellectual property rights and unfair competition
            laws. Except as expressly provided in these Terms of Use, no part of
            the Site and no Content may be copied, encoded, reproduced,
            republished, uploaded, posted, publicly displayed, translated,
            transmitted or distributed in any way (including “mirroring”) to any
            other computer, server, Web site or other medium for publication or
            distribution or for any commercial enterprise, without the express
            prior written consent of COGENT INFORMATION ANALYTICS, INC. dba
            Trautman’s Guide™.
            <br />
            You may use information on Trautman’s Guide™ products and services
            (such as our Media Kit, knowledge-based articles, and similar
            materials) purposely made available by Trautman’s Guide™ for
            downloading from the Site, provided that you (1) not remove any
            proprietary notice language in all copies of such documents, (2) use
            such information only for your personal, non-commercial
            informational purpose and do not copy or post such information on
            any networked computer or broadcast it in any media, (3) make no
            modifications to any such information, and (4) not make any
            additional representations or warranties relating to such documents.
            <br />
            COPYRIGHT
            <br />
            All content included on this site, such as audio clips, button
            icons, data compilations, digital downloads, graphics, images,
            logos, and software, is the property of COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™ or its content suppliers and
            protected by United States and international copyright laws. The
            compilation of all content on this site is the exclusive property of
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ and
            protected by U.S. and international copyright laws. All software
            used on this site is the property of COGENT INFORMATION ANALYTICS,
            INC., dba Trautman’s Guide™ or its software suppliers and protected
            by United States and international copyright laws.
          </p>
          <h2>COPYRIGHT COMPLAINTS</h2>
          <p>
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ respects
            the intellectual property of others. If you believe that your work
            has been copied in a way that constitutes copyright infringement,
            please follow our Notice and Procedure for Making Claims of
            Copyright Infringement.
          </p>
          <h2>DIGITAL MILLENNIUM COPYRIGHT ACT</h2>
          <p>
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ has
            registered itself as a Service Provider and identified an agent with
            the United States Copyright Office in accordance with the terms of
            the Digital Millennium Copyright Act (the “Act”) and avails itself
            of the protections under the Act. COGENT INFORMATION ANALYTICS,
            INC., dba Trautman’s Guide™ reserves the right to remove any content
            contained in or posted to the Website(s) that may infringe
            copyright. Notices to COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™ regarding any alleged copyright infringement on
            the Website(s) should be directed to COGENT INFORMATION ANALYTICS,
            INC., dba Trautman’s Guide™’s agents at
            copyright@trautmansguide.com. Please follow the procedures for
            reporting any suspected infringement which are available here. [link
            to "NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT
            INFRINGEMENT"]
          </p>
          <h2>DISCLAIMERS</h2>
          <p>
            TRAUTMAN’S GUIDE™ DOES NOT PROMISE THAT ANY OF OUR SITES OR ANY
            CONTENT, SERVICE OR FEATURE OF THE SITE(S) WILL BE ERROR-FREE OR
            UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT YOUR
            USE OF THE SITE WILL PROVIDE SPECIFIC RESULTS. THE SITE AND ITS
            CONTENT ARE DELIVERED ON AN “AS-IS” AND “AS-AVAILABLE” BASIS. ALL
            INFORMATION PROVIDED ON THE SITE IS SUBJECT TO CHANGE WITHOUT
            NOTICE. TRAUTMAN’S GUIDE™ CANNOT ENSURE THAT ANY FILES OR OTHER DATA
            YOU DOWNLOAD FROM THE SITE WILL BE FREE OF VIRUSES OR CONTAMINATION
            OR DESTRUCTIVE FEATURES. TO THE FULL EXTENT PERMISSIBLE BY
            APPLICABLE LAW, TRAUTMAN’S GUIDE™ DISCLAIMS ALL WARRANTIES, EXPRESS
            OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, NON-INFRINGEMENT,
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. TRAUTMAN’S
            GUIDE™ DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND
            CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR
            USE OF THE SITE AND/OR ANY TRAUTMAN’S GUIDE™ SERVICES. YOU ASSUME
            TOTAL RESPONSIBILITY FOR YOUR USE OF THE SITE AND ANY LINKED SITES.
            YOUR SOLE REMEDY AGAINST TRAUTMAN’S GUIDE™ FOR DISSATISFACTION WITH
            THE SITE OR ANY CONTENT IS TO STOP USING THE SITE OR ANY SUCH
            CONTENT. THIS LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN
            THE PARTIES.
            <br />
            CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR
            THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY
            TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR
            LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL
            RIGHTS.
            <br />
            The above disclaimer applies to any damages, liability or injuries
            caused by any communication line failure, computer virus, defect,
            delay in operation or transmission, deletion, error, failure of
            performance, omission, interruption, theft or destruction of or
            unauthorized access to, alteration of, or use, whether for breach of
            contract, tort, negligence or any other cause of action.
            <br />
            Trautman’s Guide™ reserves the right to do any of the following, at
            any time, without notice: (1) to modify, suspend or terminate
            operation of or access to the Site, or any portion of the Site, for
            any reason; (2) to modify or change the Site, or any portion of the
            Site, and any applicable policies or terms; and (3) to interrupt the
            operation of the Site, or any portion of the Site, as necessary to
            perform routine or non-routine maintenance, error correction, or
            other changes.
          </p>
          <h2>ELECTRONIC COMMUNICATIONS</h2>
          <p>
            When you visit Trautmansguide.com or send e-mails to us, you are
            communicating with us electronically. You consent to receive
            communications from us electronically. We will communicate with you
            by e-mail or by posting notices on this site. You agree that all
            agreements, notices, disclosures and other communications that we
            provide to you electronically satisfy any legal requirement that
            such communications be in writing.
            <br />
            GOVERNING LAWS: DISPUTE RESOLUTION
            <br />
            You agree that all matters relating to your access to or use of the
            Site, including all disputes, will be governed by the laws of the
            United States and by the laws of the State of Texas without regard
            to its conflicts of laws provisions. You agree to the personal
            jurisdiction by and venue in the state and federal courts in Dallas
            County, Texas, and waive any objection to such jurisdiction or
            venue. The preceding provision regarding venue does not apply if you
            are a consumer based in the European Union. If you are a consumer
            based in the European Union, you may make a claim in the courts of
            the country where you reside. Any claim under these Terms of Use
            must be brought within one (1) year after the cause of action
            arises, or such claim or cause of action is barred. Claims made
            under the separate terms and conditions of purchase for goods and
            services are not subject to this limitation. No recovery may be
            sought or received for damages other than out-of-pocket expenses,
            except that the prevailing party will be entitled to costs and
            attorneys’ fees. In the event of any controversy or dispute between
            COGENT INFORMATION ANALYTICS, INC. and/or Trautman’s Guide™ and you
            arising out of or in connection with your use of the Site, the
            parties shall attempt, promptly and in good faith, to resolve any
            such dispute. If we are unable to resolve any such dispute within a
            reasonable time (not to exceed thirty (30) days), then either party
            may submit such controversy or dispute to mediation. If the dispute
            cannot be resolved through mediation, then the parties shall be free
            to pursue any right or remedy available to them under applicable
            law.
          </p>
          <h2>INDEMNITY</h2>
          <p>
            You agree to indemnify and hold COGENT INFORMATION ANALYTICS, INC.
            dba (Trautman’s Guide™), its officers, directors, shareholders,
            predecessors, successors in interest, employees, agents,
            subsidiaries and affiliates, harmless from any demands, loss,
            liability, claims or expenses (including attorneys’ fees), made
            against COGENT INFORMATION ANALYTICS, INC., its successors, assigns,
            parents, subsidiaries and affiliates, including Trautman’s Guide™
            sites around the world (collectively, the “Site”) or Trautman’s
            Guide™ by any third party due to or arising out of or in connection
            with your use of the Site.
          </p>
          <h2>LIMITED LICENSE AND SITE ACCESS</h2>
          <p>
            Upon your acceptance of this Agreement and full and proper payment
            of all amounts due to COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™, Trautman’s Guide™ grants you a personal,
            nontransferable and nonexclusive license to possess and use the
            Trautman’s Guide™ Materials solely for your direct marketing,
            research, reference or customer prospecting purposes in strict
            accordance with the terms of this Agreement for a maximum period of
            (1) year, unless specified otherwise. Upon expiration or termination
            of this Agreement, you shall immediately discontinue your use of the
            Trautman’s Guide™ Materials. Biographical information or other
            aspects of the content may be inaccurate after it is submitted to
            Trautmansguide™. COGENT INFORMATION ANALYTICS, INC., dba Trautman’s
            Guide™ makes no warranty of the content’s accuracy. We will not be
            liable to you as a result of any inaccuracies in the content and
            your use of the content is subject to the possibility of such
            inaccuracies.You may not download (other than page caching) or
            modify it, or any portion of it, except with express written consent
            of COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™. This
            license does not include any resale or commercial use of this site
            or its contents; any collection and use of any product listings,
            descriptions, or prices; any derivative use of this site or its
            contents; any downloading or copying of account information for the
            benefit of another merchant; or any use of data mining, robots, or
            similar data gathering and extraction tools. This site or any
            portion of this site may not be reproduced, duplicated, copied,
            sold, resold, visited, or otherwise exploited for any commercial
            purpose without express written consent of COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™.
            <br />
            You may not frame or utilize framing techniques to enclose any
            trademark, logo, or other proprietary information (including images,
            text, page layout, or form) of COGENT INFORMATION ANALYTICS, INC.,
            dba Trautman’s Guide™ without express written consent. You may not
            use any meta tags or any other “hidden text” utilizing COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™’s name or
            trademarks without the express written consent of COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™. Any unauthorized use
            terminates the permission or license granted by COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™. You are granted a limited,
            revocable, and nonexclusive right to create a hyperlink to the home
            page of Trautmansguide.com so long as the link does not portray
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™, or its
            products or services in a false, misleading, derogatory, or
            otherwise offensive matter. You may not use any COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™ logo or other proprietary
            graphic or trademark as part of the link without express written
            permission.
          </p>
          <h2>LIMITATION OF LIABILITY</h2>
          <p>
            Except where prohibited by law, in no event will Trautman’s Guide™
            be liable to you for any consequential, exemplary, indirect,
            incidental or punitive damages, including lost profits, even if
            Trautman’s Guide™ has been advised of the possibility of such
            damages.
            <br />
            If, notwithstanding the other provisions of these Terms of Use,
            Trautman’s Guide™ is found to be liable to you for any damage or
            loss which arises out of or is in any way connected with your use of
            the Site or any Content, Trautman’s Guide™’s liability shall in no
            event exceed the greater of (1) the total of any subscription or
            similar fees with respect to any service or feature of or on the
            Site paid in the six months prior to the date of the initial claim
            made against Trautman’s Guide™ (but not including the purchase price
            for any Trautman’s Guide™ hardware or software products or any
            Trautman’s Guide™ support program), or (2) US$100.00. Some
            jurisdictions do not allow limitations of liability, so the
            foregoing limitation may not apply to you.
            <br />
            LINKS TO OTHER SITES AND TO THE TRAUTMAN’S GUIDE™ SITE
            <br />
            This Site may contain links to other independent third-party Web
            sites (“Linked Sites”). These Linked Sites are provided solely as a
            convenience to our visitors. Such Linked Sites are not under
            Trautman’s Guide™’s control, and Trautman’s Guide™ is not
            responsible for and does not endorse the content of such Linked
            Sites, including any information or materials contained on such
            Linked Sites. You will need to make your own independent judgment
            regarding your interaction with these Linked Sites.
            <br />
            NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT
            <br />
            If you believe that your work has been copied in a way that
            constitutes copyright infringement, please provide
            Trautmansguide.com’s copyright agent the written information
            specified below. Please note that this procedure is exclusively for
            notifying COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™
            that your copyrighted material has been infringed by providing the
            following information:
            <br />
            1. An electronic or physical signature of the person authorized to
            act on behalf of the owner of the copyright interest;
            <br />
            2. A description of the copyrighted work that you claim has been
            infringed upon;
            <br />
            3. A description of where the material that you claim is infringing
            is located on the site;
            <br />
            4. Your address, telephone number, and e-mail address;
            <br />
            5. A statement by you that you have a good-faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or
            the law;
            <br />
            6. A statement by you, made under penalty of perjury, that the above
            information in your notice is accurate and that you are the
            copyright owner or authorized to act on the copyright owner’s
            behalf.
            <br />
            Trautmansguide.com’s Copyright Agent for notice of claims of
            copyright infringement on its site can be reached as follows:
          </p>
          <h2>Copyright Agent</h2>
          <p>
            Trautmansguide.com Legal Department
            <br />
            P.O. Box 796216
            <br />
            Dallas, TX 75252
            <br />
            phone: (972)248-3382
            <br />
            fax: (972) 248-3384
            <br />
            e-mail: copyright@Trautmansguide.com
          </p>
          <p>
            Courier address:
            <br />
            Copyright Agent
            <br />
            Trautmansguide.com Legal Department
            <br />
            17304 Preston Road
            <br />
            Dallas, TX 75252
            <br />
            USA
          </p>
          <h2>OTHER BUSINESSES</h2>
          <p>
            Parties other than COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™ operate stores, provide services, or sell product
            lines on this site. In addition, we provide links to the sites of
            affiliated companies and certain other businesses. We are not
            responsible for examining or evaluating, and we do not warrant the
            offerings of, any of these businesses or individuals or the content
            of their Web sites. COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™ does not assume any responsibility or liability
            for the actions, product, and content of all these and any other
            third parties. You should carefully review their privacy statements
            and other conditions of use.
          </p>
          <h2>OUR CONTACT INFORMATION</h2>
          <p>
            COGENT INFORMATION ANALYTICS, INC.
            <br />
            dba Trautman’s Guide™
            <br />
            P.O. Box 796216
            <br />
            Dallas, TX 75379, USA.
          </p>
          <h2>PATENTS</h2>
          <p>
            One or more patents owned by COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™ may apply to this site and to the features and
            services accessible via the site. Portions of this site operate
            under license of one or more patents.
          </p>
          <h2>PRICING POLICY</h2>
          <p>
            Except where noted otherwise, the List Price displayed for products
            on our website represents the full retail price listed on the
            product itself, suggested by the manufacturer or supplier, or
            estimated in accordance with standard industry practice; or the
            estimated retail value for a comparably featured item offered
            elsewhere. The List Price is a comparative price estimate and may or
            may not represent the prevailing price in every area on any
            particular day. For certain items that are offered as a set, the
            List Price may represent “open-stock” prices, which means the
            aggregate of the manufacturer’s estimated or suggested retail price
            for each of the items included in the set. Where an item is offered
            for sale by one of our merchants, the List Price may be provided by
            the merchant.
            <br />
            With respect to items sold by COGENT INFORMATION ANALYTICS, INC.,
            dba Trautman’s Guide™, we charge your credit card at the time of
            order. Due to the nature of our business, sometimes there may be a
            delay in shipping a product. We will attempt in all cases for this
            delay to not be unreasonable.
          </p>
          <h2>PRIVACY STATEMENT</h2>
          <p>
            Please review our Statement, which also governs your visit to
            Trautmansguide.com, to understand our practices. Our Privacy
            Statement applies to use of this Site, and its terms are made a part
            of these Terms of Use by this reference. To view Trautman’s Guide™’s
            Privacy Statement, click here. Additionally, by using the Site, you
            acknowledge and agree that Internet transmissions are never
            completely private or secure. You understand that any message or
            information you send to the Site may be read or intercepted by
            others, even if there is a special notice that a particular
            transmission (for example, credit card information) is encrypted.
          </p>
          <h2>PRODUCT DESCRIPTIONS</h2>
          <p>
            COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ attempts
            to be as accurate as possible. However, COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™ does not warrant that product
            descriptions or other content of this site is accurate, complete,
            reliable, current, or error-free. If a product offered by COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ itself is not as
            described, your sole remedy is to return it in unused condition.
            <br />
            PURCHASES; OTHER TERMS AND CONDITIONS
            <br />
            Additional terms and conditions may apply to purchases of goods or
            services and to specific portions or features of the Site, including
            contests, promotions or other similar features, all of which terms
            are made a part of these Terms of Use by this reference. You agree
            to abide by such other terms and conditions, including where
            applicable representing that you are of sufficient legal age to use
            or participate in such service or feature. If there is a conflict
            between these Terms of Use and the terms posted for or applicable to
            a specific portion of the Site or for any service offered on or
            through the Site, the latter terms shall control with respect to
            your use of that portion of the Site or the specific service.
            <br />
            Trautman’s Guide™’s obligations, if any, with regard to its products
            and services are governed solely by the agreements pursuant to which
            they are provided, and nothing on this Site should be construed to
            alter such agreements.
            <br />
            Trautman’s Guide™ may make changes to any products or services
            offered on the Site, or to the applicable prices for any such
            products or services, at any time, without notice. The materials on
            the Site with respect to products and services may be out of date,
            and Trautman’s Guide™ makes no commitment to update the materials on
            the Site with respect to such products and services.
            <br />
            The following terms also govern and apply to your use of the Site,
            and they are incorporated herein by this reference:
          </p>
          <h2>Privacy Statement</h2>
          <p>
            Each of these policies may be changed from time to time and are
            effective immediately upon posting such changes on the Site.
          </p>
          <h2>RISK OF LOSS</h2>
          <p>
            All items purchased from COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™ are made pursuant to a shipment contract. This
            means that the risk of loss and title for such items pass to you
            upon our delivery to the carrier.
          </p>
          <h2>SITE POLICIES, MODIFICATION, AND SEVERABILITY</h2>
          <p>
            Please review our other policies, such as our pricing policy, posted
            on this site. These policies also govern your visit to
            Trautmansguide.com. We reserve the right to make changes to our
            site, policies, and these Conditions of Use at any time. If any of
            these conditions shall be deemed invalid, void, or for any reason
            unenforceable, that condition shall be deemed severable and shall
            not affect the validity and enforceability of any remaining
            condition.
          </p>
          <h2>TRADEMARKS AND SERVICEMARKS</h2>
          <p>
            TRAUTMAN’S GUIDE™, TRAUTMANSGUIDE.COM, TRAUTMANSGUIDE.COM &amp;
            DESIGN, TRAUTMAN’S GUIDE.CO, TRAUTMAN’S GUIDE™ TO TEXAS CORPORATE
            DIRECTORS, TRAUTMAN’S GUIDE™ TO CALIFORNIA CORPORATE DIRECTORS,
            TRAUTMAN’S GUIDE™ TO VENTURE CAPITALISTS, TRAUTMAN’S GUIDE™ TO NEW
            YORK CORPORATE DIRECTORS, TRAUTMAN’S GUIDE™ TO MASSACHUSETTS
            CORPORATE DIRECTORS, TRAUTMAN’S GUIDE™ TO FLORIDA CORPORATE
            DIRECTORS, TRAUTMAN’S GUIDE™ TO ILLINOIS CORPORATE DIRECTORS,
            TRAUTMAN’S GUIDE™ TO CORPORATE DIRECTORS (MIDATLANTIC), TRAUTMAN’S
            GUIDE™ TO CORPORATE DIRECTORS (MID-WEST), TRAUTMAN’S GUIDE™ TO
            CORPORATE DIRECTORS (NEW ENGLAND), TRAUTMAN’S GUIDE™ TO CORPORATE
            DIRECTORS (PACIFIC), TRAUTMAN’S GUIDE™ TO CORPORATE DIRECTORS (SOUTH
            EAST), TRAUTMAN’S GUIDE™ TO CORPORATE DIRECTORS (SOUTH WEST),
            TRAUTMAN’S GUIDE™ TO CORPORATE DIRECTORS (WESTERN), TRAUTMAN’S
            GUIDE™ TO DOING BUSINESS IN CHINA, TRAUTMAN’S GUIDE™ TO DOING
            BUSINESS IN INDIA, TRAUTMAN’S GUIDE™ TO DOING BUSINESS IN BRAZIL,
            TRAUTMAN’S GUIDE™ TO DOING BUSINESS IN CANADA, TRAUTMAN’S GUIDE™ TO
            DOING BUSINESS IN MEXICO, TRAUTMAN’S GUIDE™ TO MICRO-CAP EQUITIES,
            MISSION CRITICAL BUSINESS INTELLEGENCE™, DIRECTOR’S MARKETPLACE™,
            THE AUTHORITATIVE RESOURCE FOR CORPORATE DIRECTOR SELECTION AND
            RECRUITMENT™, LAWRENCETRAUTMAN.COM, LAWRENCEJTRAUTMAN.COM,
            LJTRAUTMAN.COM, LARRY TRAUTMAN.COM, and other Trautmansguide.com
            graphics, logos, page headers, button icons, scripts, and service
            names are trademarks, registered trademarks or trade dress of COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ in the U.S.
            and/or other countries. COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™’s trademarks and trade dress may not be used in
            connection with any product or service that is not COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™’s, in any manner
            that is likely to cause confusion among customers, or in any manner
            that disparages or discredits COGENT INFORMATION ANALYTICS, INC.,
            dba Trautman’s Guide™. All other trademarks not owned by COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ that appear on
            this site are the property of their respective owners, who may or
            may not be affiliated with, connected to, or sponsored by COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™.
          </p>
          <h2>USER UPLOADED INFORMATION</h2>
          <p>
            Trautman’s Guide™ may allow you to upload materials other than
            biographical submissions (“User Uploaded Information”) to the
            Website(s). As an example, the Website(s) may offer blogs, bulletin
            boards, chat rooms, forums, or other interactive content. COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ does not endorse
            any User Uploaded Information. Your use or submission of any User
            Uploaded Information is at your own risk. COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™ reserves the right to edit,
            delete or move any User Uploaded Information, in whole or in part,
            for any reason in its sole discretion. COGENT INFORMATION ANALYTICS,
            INC., dba Trautman’s Guide™ undertakes no obligation to pre-screen
            User Uploaded Information, but may, at its sole discretion, block
            access to, limit, modify, monitor, move, refuse, transmit over
            various networks, or remove any User Uploaded Information. You agree
            not to accept payment for User Uploaded Information from any third
            party, including, without limitation, accepting payment for the
            inclusion of a logo, brand advertising or other commercial content
            contained therein. You remain solely responsible for all User
            Uploaded Information that you make available via any of the
            Website(s). Under no circumstances will COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™ be liable in any way for any
            User Uploaded Information including, but not limited to, any errors
            or omissions in User Uploaded Information, or for any loss or damage
            of any kind whatsoever incurred as a result of User Uploaded
            Information. When you upload User Uploaded Information via the
            Website(s) you grant to COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™, without any credit or compensation to you, a
            royalty-free, irrevocable, non-exclusive, perpetual, unrestricted,
            worldwide, and fully transferable, assignable and sub-licensable
            license to create derivative works of, distribute, modify,
            reproduce, use, and otherwise use such User Uploaded Information, in
            whole or in part, or to incorporate it in other works in any form,
            media, software or technology of any kind, now existing or
            subsequently available, for any purposes whatsoever.
          </p>
          <h2>VIOLATION OF THESE TERMS OF USE</h2>
          <p>
            Trautman’s Guide™ may disclose any information we have about you
            (including your identity) if we determine that such disclosure is
            necessary in connection with any investigation or complaint
            regarding your use of the Site, or to identify, contact or bring
            legal action against someone who may be causing injury to or
            interference with (either intentionally or unintentionally)
            Trautman’s Guide™’s rights or property, or the rights or property of
            visitors to or users of the Site, including Trautman’s Guide™’s
            customers. COGENT INFORMATION ANALYTICS INC., and/or Trautman’s
            Guide™ reserves the right at all times to disclose any information
            that Trautman’s Guide™ deems necessary to comply with any applicable
            law, regulation, legal process or governmental request. Trautman’s
            Guide™ also may disclose your information when Trautman’s Guide™
            determines that applicable law requires or permits such disclosure,
            including exchanging information with other companies and
            organizations for fraud protection purposes.
            <br />
            You acknowledge and agree that Trautman’s Guide™ may preserve any
            transmittal or communication by you with Trautman’s Guide™ through
            the Site or any service offered on or through the Site, and may also
            disclose such data if required to do so by law or Trautman’s Guide™
            determines that such preservation or disclosure is reasonably
            necessary to (1) comply with legal process, (2) enforce these Terms
            of Use, (3) respond to claims that any such data violates the rights
            of others, or (4) protect the rights, property or personal safety of
            Trautman’s Guide™, its employees, users of or visitors to the Site,
            and the public.
            <br />
            You agree that Trautman’s Guide™ may, in its sole discretion and
            without prior notice, terminate your access to the Site and/or block
            your future access to the Site if we determine that you have
            violated these Terms of Use or other agreements or guidelines which
            may be associated with your use of the Site. You also agree that any
            violation by you of these Terms of Use will constitute an unlawful
            and unfair business practice, and will cause irreparable harm to
            Trautman’s Guide™, for which monetary damages would be inadequate,
            and you consent to Trautman’s Guide™ obtaining any injunctive or
            equitable relief that Trautman’s Guide™ deems necessary or
            appropriate in such circumstances. These remedies are in addition to
            any other remedies Trautman’s Guide™ may have at law or in equity.
            <br />
            You agree that Trautman’s Guide™ may, in its sole discretion and
            without prior notice, terminate your access to the Site, for cause,
            which includes (but is not limited to) (1) requests by law
            enforcement or other government agencies, (2) a request by you
            (self-initiated account deletions), (3) discontinuance or material
            modification of the Site or any service offered on or through the
            Site, or (4) unexpected technical issues or problems.
            <br />
            If COGENT INFORMATION ANALYTICS, INC., dba Trautman’s Guide™, its
            successors, assigns, parents, subsidiaries and affiliates, including
            Trautman’s Guide™ sites around the world (collectively, the “Site”)
            does take any legal action against you as a result of your violation
            of these Terms of Use, COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™, its successors, assigns, parents, subsidiaries
            and affiliates, including Trautman’s Guide™ sites around the world
            (collectively, the “Site”) will be entitled to recover from you, and
            you agree to pay, all reasonable attorneys’ fees and costs of such
            action, in addition to any other relief granted to Trautman’s
            Guide™. You agree that COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™, its successors, assigns, parents, subsidiaries
            and affiliates, including Trautman’s Guide™ sites around the world
            (collectively, the “Site”). Trautman’s Guide™ will not be liable to
            you or to any third party for termination of your access to the Site
            as a result of any violation of these Terms of Use.
          </p>
          <h2>VOID WHERE PROHIBITED</h2>
          <p>
            Trautman’s Guide™ administers and operates the
            www.Trautmansguide.com Site from its location in Dallas, Texas USA;
            other Trautman’s Guide™ sites may be administered and operated from
            various locations outside the United States. Although the Site is
            accessible worldwide, not all features, products or services
            discussed, referenced, provided or offered through or on the Site
            are available to all persons or in all geographic locations, or
            appropriate or available for use outside the United States.
            Trautman’s Guide™ reserves the right to limit, in its sole
            discretion, the provision and quantity of any feature, product or
            service to any person or geographic area. Any offer for any feature,
            product or service made on the Site is void where prohibited. If you
            choose to access the Site from outside the United States, you do so
            at your own initiative and you are solely responsible for complying
            with applicable local laws.
          </p>
          <h2>YOUR ACCOUNT</h2>
          <p>
            If you use this site, you are responsible for maintaining the
            confidentiality of your account and password and for restricting
            access to your computer, and you agree to accept responsibility for
            all activities that occur under your account or password. COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™ does sell
            products for children, but it sells them to adults, who can purchase
            with a credit card or other permitted payment method. If you are
            under 18, you may use Trautmansguide.com only with involvement of a
            parent or guardian. COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™ reserves the right to refuse service, terminate
            accounts, remove or edit content, or cancel orders in their sole
            discretion.
          </p>
          <h2>YOUR RESPONSIBILITIES</h2>
          <p>
            Except to the extent specifically authorized in writing by COGENT
            INFORMATION ANALYTICS, INC., dba Trautman’s Guide™, you cannot share
            or sell the Trautman’s Guide™ Materials and you are responsible to
            Trautman’s Guide™ for any use of it. You agree and warrant that: a)
            you will not provide access to any portion of the Trautman’s Guide™
            Materials to any unauthorized party without the prior written
            consent of COGENT INFORMATION ANALYTICS, INC., dba Trautman’s
            Guide™; b) you shall use your best efforts to prevent the misuse and
            unauthorized use of the Trautman’s Guide™ Materials; c) you will not
            use any Trautman’s Guide™ Materials for consumer credit purposes,
            consumer insurance underwriting, employment purposes, tenant
            screening purposes, or for any other purpose(s) covered by the
            federal Fair Credit Reporting Act (15 U.S.C. § 1681 et seq.) or any
            similar statute or regulation; c) you shall not name Trautman’s
            Guide™ or its affiliated companies or refer to the use of Trautman’s
            Guide™ Materials in any advertisements, promotional or marketing
            material; and d) you shall indemnify and hold harmless Infogroup,
            its affiliated companies and their respective officers, employees
            and agents (“Indemnified Parties”) against any loss, liability,
            damage, cost or expense (including, but not limited to, reasonable
            attorneys’ fees and legal costs) suffered or incurred by any
            Indemnified Party, arising out of or in any way related to your use
            of the Trautman’s Guide™ Materials or the breach of this Agreement.
          </p>
          <h2>YOUR USE OF THE SITE</h2>
          <p>
            You may not use any “deep-link”, “page-scrape”, “robot”, “spider” or
            other automatic device, program, algorithm or methodology, or any
            similar or equivalent manual process, to access, acquire, copy or
            monitor any portion of the Site or any Content, or in any way
            reproduce or circumvent the navigational structure or presentation
            of the Site or any Content, to obtain or attempt to obtain any
            materials, documents or information through any means not purposely
            made available through the Site. COGENT INFORMATION ANALYTICS, INC.,
            dba Trautman’s Guide™, its successors, assigns, parents,
            subsidiaries and affiliates, including Trautman’s Guide™ sites
            around the world (collectively, the “Site”) and/or Trautman’s Guide™
            reserves the right to bar any such activity.
            <br />
            You may not attempt to gain unauthorized access to any portion or
            feature of the Site, or any other systems or networks connected to
            the Site or to any Trautman’s Guide™ server, or to any of the
            services offered on or through the Site, by hacking, password
            “mining” or any other illegitimate means.
            <br />
            You may not probe, scan or test the vulnerability of the Site or any
            network connected to the Site, nor breach the security or
            authentication measures on the Site or any network connected to the
            Site. You may not reverse look-up, trace or seek to trace any
            information on any other user of or visitor to the Site, or any
            other customer of Trautman’s Guide™, including any Trautman’s Guide™
            account not owned by you, to its source, or exploit the Site or any
            service or information made available or offered by or through the
            Site, in any way where the purpose is to reveal any information,
            including but not limited to personal identification or information,
            other than your own information, as provided for by the Site.
            <br />
            You agree that you will not take any action that imposes an
            unreasonable or disproportionately large load on the infrastructure
            of the Site or Trautman’s Guide™’s systems or networks, or any
            systems or networks connected to the Site or to Trautman’s Guide™.
            <br />
            You agree not to use any device, software or routine to interfere or
            attempt to interfere with the proper working of the Site or any
            transaction being conducted on the Site, or with any other person’s
            use of the Site.
            <br />
            You may not forge headers or otherwise manipulate identifiers in
            order to disguise the origin of any message or transmittal you send
            to Trautman’s Guide™ on or through the Site or any service offered
            on or through the Site. You may not pretend that you are, or that
            you represent, someone else, or impersonate any other individual or
            entity.
            <br />
            You may not use the Site or any Content for any purpose that is
            unlawful or prohibited by these Terms of Use, or to solicit the
            performance of any illegal activity or other activity which
            infringes the rights of Trautman’s Guide™ or others.
          </p>
          <h2>MISCELLANEOUS</h2>
          <p>
            You may not use or export or re-export any Content or any copy or
            adaptation of such Content, or any product or service offered on the
            Site, in violation of any applicable laws or regulations, including
            without limitation United States export laws and regulations.
            <br />
            If any of the provisions of these Terms of Use are held by a court
            or other tribunal of competent jurisdiction to be void or
            unenforceable, such provisions shall be limited or eliminated to the
            minimum extent necessary and replaced with a valid provision that
            best embodies the intent of these Terms of Use, so that these Terms
            of Use shall remain in full force and effect. These Terms of Use
            constitute the entire agreement between you and COGENT INFORMATION
            ANALYTICS, INC., dba Trautman’s Guide™, its successors, assigns,
            parents, subsidiaries and affiliates, including Trautman’s Guide™
            sites around the world (collectively, the “Site”) with regard to
            your use of the Site, and any and all other written or oral
            agreements or understandings previously existing between you and
            Trautman’s Guide™ with respect to such use are hereby superseded and
            cancelled. Other than as provided in a purchase agreement you enter
            into with Trautman’s Guide™, Trautman’s Guide™ will not accept any
            counter-offers to these Terms of Use, and all such offers are hereby
            categorically rejected. COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™, its successors, assigns, parents, subsidiaries
            and affiliates, including Trautman’s Guide™ sites around the world,
            (collectively, the “Site), or Trautman’s Guide™’s failure to insist
            on or enforce strict performance of these Terms of Use shall not be
            construed as a waiver by COGENT INFORMATION ANALYTICS, INC., dba
            Trautman’s Guide™, its successors, assigns, parents, subsidiaries
            and affiliates, including Trautman’s Guide™ sites around the world
            (collectively, the “Site”) of any provision or any right it has to
            enforce these Terms of Use, nor shall any course of conduct between
            Trautman’s Guide™ and you or any other party be deemed to modify any
            provision of these Terms of Use. These Terms of Use shall not be
            interpreted or construed to confer any rights or remedies on any
            third parties.
          </p>
          <h2>FEEDBACK AND INFORMATION</h2>
          <p>
            Any feedback you provide at this site shall be deemed to be
            non-confidential. COGENT INFORMATION ANALYTICS, INC., dba Trautman’s
            Guide™, its successors, assigns, parents, subsidiaries and
            affiliates, including Trautman’s Guide™ sites around the world
            (collectively, the “Site”) shall be free to use such information on
            an unrestricted basis.
            <br />
            USE OF THE TRAUTMAN’S GUIDE™MATERIALS PROVIDED CONSTITUTES YOUR
            CONSENT TO THIS AGREEMENT. DO NOT USE THE TRAUTMAN’S GUIDE™
            MATERIALS IF YOU FIND THESE TERMS UNACCEPTABLE
            <br />
            Effective Date: August 1, 2010
            <br />
            The information contained in this web site is subject to change
            without notice.
            <br />
            Copyright © 2010 COGENT INFORMATION ANALYTICS, INC. , dba Trautman’s
            Guide™. All rights reserved.
            <br />
            Trautman’s Guide™, P.O. Box 796216, Dallas, TX 75379, USA.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default RecentScholarlyImpactPage
